import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { FaArrowRightLong } from "react-icons/fa6";
import mobile_One from "../../assets/success/new-mobile-1.png";
import mobile_two from "../../assets/success/new-mobile-2.png";
import mobile_three from "../../assets/success/new-mobile-3.png";
import boy from "../../assets/success/boy.png";
import boy_two from "../../assets/success/boy-two.png";
import "./success.css";
import arrow_download from "../../assets/success/arrow-download.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SuccessComponent = () => {
  const notify = () => toast("App will be Available Soon!");

  return (
    <section id="home" data-section>
      <Container>
        <div className="success-container">
          <Row>
            <Col className="success-content justify-content-center center">
              <h5 className="success-heading">Learn.Grow.Succeed</h5>
              <p className="success-para">
                Unlock the Power of Learning: Where Curiosity Meets Success.
                Dive into a world of limitless knowledge, innovative tools, and
                personalized learning experiences designed to empower you every
                step of the way.
              </p>
              <div className="success-btn">
                <button className="btn" onClick={notify}>
                  Install app
                  <FaArrowRightLong className="arrow-icon" />
                  <ToastContainer />
                </button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="justify-content-center center">
              <div className="mobile-images-container">
                <img className="mobile-one" src={mobile_One} alt="" />
                <img className="mobile-two" src={mobile_two} alt="" />
                <img className="mobile-three" src={mobile_three} alt="" />
              </div>
            </Col>
          </Row>
          {/* about */}
          <section id="about" data-section>
            <Row className="about-main-container">
              <Col lg={5} className="justify-content-center center">
                <img className="laddy-image" src={boy} alt="" />
              </Col>
              <Col lg={7} className="justify-content-center center">
                <div className="about-content-container">
                  <div>Our Commitment to Excellence</div>
                  <h5 className="about-head">About us</h5>
                  <p className="about-para">
                  "
                  <span className="iq-cls">IQ</span>
                  <span className="win-cls">WIN</span> is a comprehensive educational app designed to assist
                    children in mastering mathematics through simple tricks and
                    easy-to-understand methods. This innovative app not only
                    makes learning math fun and engaging but also focuses on the
                    specific curriculum requirements of CBSE students from
                    classes 6 to 10. By offering a range of video lessons,
                    practice problems, and step-by-step solutions, <span className="iq-cls">IQ</span>
                    <span className="win-cls">WIN</span> helps
                    students build a strong foundation in mathematical concepts.
                    The app covers all essential topics in the CBSE syllabus,
                    ensuring that students are well-prepared for their exams and
                    can achieve academic success. Additionally, <span className="iq-cls">IQ</span>
                    <span className="win-cls">WIN</span> provides
                    personalized learning experiences, adapting to each
                    student's pace and level of understanding, making it an
                    ideal tool for both classroom learning and independent
                    study."
                  </p>
                </div>
              </Col>
            </Row>
          </section>
          <Container>
            <Row className="download-app-section content-text">
              <Col lg={4}>
                <img className="laddy-img" alt="" src={boy_two} />
              </Col>
              <Col lg={8} className="text-div">
                <div className="texts">"To Unlock Knowledge: Explore Our Courses Right now"</div>
                <button className="download-btn">
                  Download app <img src={arrow_download} alt="" />
                </button>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </section>
  );
};

export default SuccessComponent;
