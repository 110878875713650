import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import capImageOne from "../../assets/service/cap-img-one.svg";
import capImageTwo from "../../assets/service/cap-img-two.svg";
import "./services.css";
import ListServicesComponent from "./list_services/list_services";

const ServiceComponent = () => {
  return (
    <section id="service" data-section>
      <Container>
        <div className="service-container">
          <Row>
            <Col
              lg={7}
              className="service-content justify-content-center center"
            >
              <div className="our-services">
                <p className="our-service-p">What we provide for you</p>
                <div className="heading-para">
                  <h5 className="our-service-head">Our Services</h5>
                  <p className="our-service-para">
                    Our services include expert-led online courses across
                    diverse subjects and a flexible learning platform tailored
                    to your schedule and preferences. Join our global community
                    and unlock new opportunities for personal and professional
                    growth. Our services include expert-led online courses
                    across diverse subjects and a flexible learning platform
                    tailored to your schedule and preferences. Join our global
                    community and unlock new opportunities for personal and
                    professional growth
                  </p>
                </div>
              </div>
              <div className="service-lists">
                <div className="first-service-list">
                  <img className="cap-img" src={capImageOne} alt="" />
                  <h5>Basic Vedic Math - A Beginner's Guide</h5>
                </div>
                <div className="second-service-list">
                  <img className="cap-img" src={capImageTwo} alt="" />
                  <h5>Math Genius - Numbers to Algebra</h5>
                </div>
              </div>

              {/* <div className="our-service2">
                <h6 className="heading-service2">
                  Math Genius - Numbers to Algebra
                </h6>
                <p className="our-service-para">
                  Unlock your potential with this dynamic course designed to
                  transform your understanding of mathematics from basic numbers
                  to the elegance of algebra. Dive into the world of arithmetic,
                  master fractions, decimals, and percentages, and explore the
                  beauty of algebraic expressions. With engaging lessons and
                  challenging exercises, you'll develop critical thinking,
                  logical Reasoning and problem-solving skills. This course is
                  perfect for those looking to excel and gain a deep
                  appreciation for math. Embrace the journey from numbers to
                  algebra and become a math genius!
                </p>
              </div> */}
            </Col>
            <Col lg={5} className="about-content justify-content-center center">
              <ListServicesComponent />
            </Col>
            <Col>
              {/* <div className="our-service3">
              <h6 className="heading-service">
                CBSE Math Grade 5 to Grade 10 - Learn & Win Score!
              </h6>
              <p className="our-service-para">
                Embark on a comprehensive mathematical journey tailored for CBSE
                students from Grade 5 to Grade 10. The App covers the entire
                CBSE math curriculum, offering clear explanations, engaging
                lessons, and plenty of practice problems. Strengthen your
                understanding of key concepts, from basic arithmetic to advanced
                algebra and geometry. With a focus on exam preparation, you'll
                develop problem-solving skills and test-taking strategies to
                boost your confidence and scores. Perfect for students aiming
                for excellence, this course will help you learn, excel, and win
                in math!
              </p>
              </div> */}
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};
export default ServiceComponent;
