import React, { useState } from "react";
import LeftArrow from "./../../../assets/service/leftArrow.svg";
import RightArrow from "./../../../assets/service/rightArrow.svg";
import cap from "./../../../assets/service/service-list-cap.svg";
import "./list_services.css";

const services = [
  {
    title: "1.Basic Vedic Math - A Beginner's Guide ",
    description: `Discover the ancient secrets of rapid calculation with this beginner's guide to Vedic Mathematics. This course introduces the foundational techniques of Vedic Math, enabling you to perform complex arithmetic operations with ease and speed. Learn simple yet powerful methods for addition, subtraction, multiplication, and division that can enhance your mental math skills. Through engaging lessons and practical exercises, you'll gain confidence and proficiency in mathematical computations. Ideal for students and enthusiasts, this course makes math faster, fun, and incredibly efficient. Start your journey into the world of Vedic Math today!`,
  },
  {
    title: "2.Math Genius - Numbers to Algebra",
    description: `Unlock your potential with this dynamic course designed to transform your understanding of mathematics from basic numbers to the elegance of algebra. Dive into the world of arithmetic, master fractions, decimals, and percentages, and explore the beauty of algebraic expressions. With engaging lessons and challenging exercises, you'll develop critical thinking, logical Reasoning and problem-solving skills. This course is perfect for those looking to excel and gain a deep appreciation for math. Embrace the journey from numbers to algebra and become a math genius!`,
  },
  {
    title: "3.CBSE Math Grade 5 to Grade 10 - Learn & Win Score!",
    description: `Embark on a comprehensive mathematical journey tailored for CBSE students from Grade 5 to Grade 10. The App covers the entire CBSE math curriculum, offering clear explanations, engaging lessons, and plenty of practice problems. Strengthen your understanding of key concepts, from basic arithmetic to advanced algebra and geometry. With a focus on exam preparation, you'll develop problem-solving skills and test-taking strategies to boost your confidence and scores. Perfect for students aiming for excellence, this course will help you learn, excel, and win in math!`,
  },
  {
    title: "4.MAT Genius - Brain Teasers and Mathematical Puzzles",
    description: `Enhance your mental agility with this exciting course filled with brain teasers and mathematical puzzles. Designed to challenge and stimulate, each lesson offers a variety of problems that develop critical thinking, logical reasoning, and problem-solving skills. From classic riddles to modern conundrums, you'll explore intriguing puzzles that make math fun and engaging. Perfect for those who love a good challenge, this course will sharpen your mind and boost your mathematical prowess. Get ready to think outside the box and become a true MAT genius!`
  }
];

const ListServicesComponent = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleLeftClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? services.length - 1 : prevIndex - 1
    );
  };

  const handleRightClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === services.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <section id="service-list">
      <div className="main-service-list">
        <img className="cap-img-list" src={cap} alt="" />
        <div className="service-description-container">
          <h5 className="service-list-head">{services[currentIndex].title}</h5>
          <p className="service-list-para">
            {services[currentIndex].description}
          </p>
        </div>
        <div className="arrow-container">
          <div className="arrow-div" onClick={handleLeftClick}>
            <img src={LeftArrow} alt="Previous" />
          </div>
          <div className="arrow-div" onClick={handleRightClick}>
            <img src={RightArrow} alt="Next" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ListServicesComponent;
