import React from "react";
import { Container } from "react-bootstrap";

const PrivacyPolicy = () => {
  return (
    <section style={{ textAlign: "start" }} id="privacypolicy">
      <Container>
        <h1 className="heading my-5" style={{ textAlign: "center" }}>
          Privacy Policy
        </h1>
        <p className="para">
          This Privacy Policy governs your use of IQWIN App' ("Application"),
          the other associated applications, products, websites, and services
          managed or offered by the Winscore Edutech Private ltd both online, as
          well as offline . Please read this Privacy Policy carefully before
          using the Application, Website, or availing any of our services and
          products, along with the Terms of Use ( "ToU "or" Terms" ) provided on
          the Application, Website.
        </p>
        <p className="para">
          Your use of the Application, and/or services in connection with the
          Application, or any of our services or offline coaching centre
          offerings (collectively referred to as "Services"), or any
          registrations made with us through any mode or usage of any of our
          products shall signify your acceptance of this Privacy Policy.
        </p>
        <p className="para">
          If you do not agree with the terms of this Privacy Policy, please do
          not use the Website, Application, or avail any of our Services.
        </p>
        <h2 className="sub-headings">User Provided Information</h2>
        <p className="para">
          We obtain the information you provide when you download and register
          for or on the Application, Website or Services, or through your use of
          the Services. When you register with us, you generally provide, your
          name, age, email address, phone number, password and educational
          interests.
        </p>
        <h2 className="sub-headings">Use of Personal Information</h2>
        <p className="para">
          We may use the Information to contact you from time to time, to
          provide you with the Application, Website or Services.
        </p>
        <p className="para">
          Further, you shall, at any time while using the
          Website/Application/Services, also have an option to withdraw your
          consent given earlier to us to use such Personal Information or
          Sensitive Personal Information. Such withdrawal of consent is required
          to be sent in writing to us at the contact details provided in this
          Privacy Policy below. In such event, however, the IQWIN fully reserves
          the right not to allow further usage of the Application or Website or
          provide any of our Services to you.
        </p>
        <h2 className="sub-headings">Automatically Collected Information</h2>
        <p className="para">
          In addition, the Application/Website/Services may collect certain
          information automatically, including, but not limited to, the type of
          mobile device you uses, such mobile device's unique device ID, IP
          address, and mobile operating system, the type of mobile internet
          browsers used, and information about the way you or a use the
          Website/Application/Services. We also collect other relevant
          information as per the permissions that are provided.
        </p>
        <p className="para">
          IQWIN will occasionally send email notices, messages or contact you to
          communicate about our Application/Services, as they are considered an
          essential part of the Services you have chosen.
        </p>
        <h2 className="sub-headings">
          Please note that we may disclose Information:
        </h2>
        <p className="para">
          as required by law, such as to comply legal process; to enforce this
          Privacy Policy and the applicable Terms of Use, including for the
          investigation of potential violations thereof;
        </p>
        <p className="para">
          when we believe in good faith that disclosure is necessary to protect
          our rights, protect your safety or the safety of others, investigate
          fraud, address security or technical issues or respond to a
          governmental request;
        </p>
        <p className="para">
          with our trusted services providers who work on our behalf, do not
          have an independent use of the information we disclose to them , and
          have agreed to adhere to the rules set forth in this Privacy Policy;
        </p>
        <p className="para">
          to protect against imminent harm to the rights, property or safety of
          the Application or Services, or the IQWIN, its users or the public as
          required or permitted by law;
        </p>
        <h2 className="sub-headings">Access to Personal Information</h2>
        <p className="para">
          We will provide you with the means to review and ensure that your
          Personal Information, including Sensitive Personal Information is
          correct, updated, and current. If you have filled out a user profile,
          we will provide an obvious way for you to access and change your
          profile from our application or Website. We adopt reasonable security
          measures to protect your password from being exposed or disclosed to
          anyone.
        </p>
        <p className="para">
          Please be aware that although we endeavour to provide reasonable
          security for Information we process and maintain, no security system
          can prevent all potential security breaches.
        </p>
        <p className="para">
          If we become aware that your Personal Information or Sensitive
          Personal Information has been accessed or disclosed in a manner not in
          accordance with this Privacy Policy, we will on a best-efforts basis
          notify you of the nature and extent of such access or disclosure (to
          the extent we know of such access or disclosure) as soon as reasonably
          possible and as permitted by applicable laws.
        </p>
        <h2 className="sub-headings">How Long Do We Retain Information?</h2>
        <p className="para">
          Currently, we only retain your User's Information while your account
          is active and for at least three years afterward. We may alter this
          practice according
        </p>
        <h2 className="sub-headings">Changes to this Statement</h2>
        <p className="para">
          You understand our Privacy Policy will need to evolve as well to cover
          new situations. You are advised to review this Privacy Policy
          regularly for any changes, as continued use is deemed approval of all
          changes.
        </p>
        <h2 className="sub-headings">Your Consent</h2>

        <p className="para">
          You should be in a position to provide informed consent prior to
          providing any Information required for the use of the
          Application/Services/Website. By registering with us or by
          downloading, accessing, purchasing, or using our
          Website/Application/Services, you are expressly consenting to our
          collection, processing, storing, disclosing, and handling of your as
          set forth in this PrivacyPolicy , now and as amended by us, and to the
          provisions of this Privacy Policy as a whole.
        </p>
        <p className="para">
          By accepting this Privacy Policy, you represent that you are eighteen
          years of age or above.
        </p>
        <h2 className="sub-headings">Contact Information</h2>
        <p className="para">
          You may contact us at
          <a style={{textDecoration:"none",padding:"0px 10px"}} href="tel:+918590683319">+918590683319</a> And we shall
          undertake all reasonable efforts to address your grievances at the
          earliest possible opportunity.
        </p>
      </Container>
    </section>
  );
};
export default PrivacyPolicy;
