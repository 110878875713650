import React from "react";
import { Container } from "react-bootstrap";

const Terms = () => {
  return (
    <section style={{ textAlign: "start" }} id="terms">
      <Container>
        <h1 className="heading my-5" style={{ textAlign: "center" }}>
          Terms & Conditions
        </h1>
        <p className="para">
          These Terms and Conditions ("Terms" or "ToU") constitute an agreement
          between Winscore Edutech Private ltd (owners of IQWIN) (referred to as
          "IQWIN", "we", "us", or "our") and its users (referred to as "you",
          "your", or "yourself"). These Terms regulate your utilization of our
          Website, Application, and Services, as well as any registrations made
          with us via any means or the usage of any of our products.
        </p>
        <p className="para">
          References to "you", "your", and "yourself" pertain to any individual
          who is a natural person aged 18 (eighteen) years or older, competent,
          and capable of contracting within the applicable laws and includes any
          parents utilizing our Application/Website/Services and registered as a
          user.
        </p>
        <p className="para">
          Please carefully review the Terms and the Privacy Policy before using,
          registering, or accessing our Application, Website, or Services.
          Additionally, note that any access to our Services/Application/Website
          through registrations/subscriptions is non-transferable and
          non-refundable.
        </p>
        <p className="para">
          Except as specified below, all information, content, materials,
          trademarks, service marks, trade names, and trade secrets, including
          but not limited to software, text, images, graphics, videos, scripts,
          and audio, contained within the Application, Website, and Services are
          the proprietary property of IQWIN ("Proprietary Information").
          Furthermore, you acknowledge that the ownership of all trademarks,
          copyrights, logos, service marks, and other intellectual property
          owned by any third party shall remain with such third party, and you
          are not permitted to use them without the consent of the respective
          third party.
        </p>
        <p className="para">
          Your use of our Website, Application, and Services is solely for
          personal and non-commercial purposes. Any use of our Application,
          Website, Services, or their contents for other than personal purposes
          is prohibited. You shall not decompile, reverse engineer, or
          disassemble the contents of our Application, Website, and/or Services,
          nor modify, copy, distribute, transmit, display, perform, reproduce,
          publish, license, create derivative works from, transfer, or sell any
          information or software obtained from our Application, Website, and/or
          Services, nor remove any copyright, trademark registration, or other
          proprietary notices from the contents of the Application, Website,
          and/or Services.
        </p>
        <p className="para">
          You shall not use the Application, Website, and/or Services in any
          manner that is unlawful or that harms IQWIN. You agree not to engage
          in any prohibited activities while using our Services, including but
          not limited to:
        </p>
        <p className="para">
          - Making available any content or material that violates any law
          currently in force.
        </p>
        <p className="para">
          - Making, transmitting, or storing electronic copies of materials
          protected by copyright without the owner's permission, or engaging in
          any act that constitutes infringement of intellectual property.
        </p>
        <p className="para">
          - Intentionally or unintentionally interfering with or disrupting our
          Services or violating any applicable laws related to access to or use
          of our Application/Website/Services.
        </p>
        <p className="para">
          We disclaim any responsibility for errors or inaccuracies in the
          information contained in the Application/Website/Services. IQWIN
          reserves the right and discretion to make changes, corrections, or
          withdrawals/additions to content or material at any time without
          notice.
        </p>
        <p className="para">
          Our application provides users with access to information on an "As
          Is" basis, and we assume no liability for the accuracy, completeness,
          or non-obsolescence of such information. Additionally, we make no
          warranties or representations whatsoever regarding the quality,
          content, completeness, or adequacy of such information and data.
        </p>
        <p className="para">
          Our Services, including the Application and Website, and the content
          therein, are compatible only with certain devices, and we are not
          obligated to provide functional products and/or services for any
          instruments not recognized by us. We are not responsible for any loss
          or damage to any hardware, software, or electronic devices resulting
          from your use of our Application/Website/Services.
        </p>
        <p className="para">
          You agree to furnish true, accurate, current, and complete information
          about yourself as requested in the IQWIN Sites’ registration forms
          (referred to as "Registration Data"). Maintaining accurate records
          assists us in enhancing our sites and enables us to identify new
          services or products that may be of interest to you. Kindly ensure to
          update the Registration Data to ensure its current and accurate
          status.
        </p>
        <p className="para">
          You are accountable for safeguarding the confidentiality of your
          password and account. You bear full responsibility for all activities
          conducted using your password or account. Promptly inform IQWIN of any
          unauthorized use of your password or account or any other security
          breach.
        </p>
        <p className="para">
          You undertake not to employ the service for unlawful purposes or for
          transmitting material that is illegal, harassing, libelous (false and
          damaging to others), intrusive of another's privacy, abusive,
          threatening, or obscene, or that violates the copyrights or other
          intellectual property rights of others.
        </p>
        <p className="para">
          All Web site/App design, text, graphics, the selection and arrangement
          thereof, and all software that are part of the IQWIN Sites are
          protected by international copyright laws. The publication, sale, or
          redistribution in any form or medium of text, photos, graphics, audio
          and/or video materials or any other form of proprietary content found
          on the IQWIN Sites is strictly prohibited without the prior written
          permission of Winscore Edutech Private ltd. The Content that is
          publicly available on the IQWIN app may not be stored in a computer,
          except for personal and non-commercial use. This site (IQWIN), and all
          of the content of the site, is protected by intellectual property and
          other laws throughout the world. Unauthorized use of the site would be
          a violation of ' and its licensors' rights. In addition, the
          trademarks, logos and service marks displayed on this site
          (collectively, the "Trademarks") are the property of IQWIN, its
          affiliates, and various third parties. The site and the software
          incorporated in the site may not be reverse engineered, decompiled or
          disassembled.
        </p>
        <p className="para">
          By using any of the IQWIN web sites or networks you agree not to data
          scrape, copy, aggregate, redistribute, alter, reproduce or re-use for
          commercial purposes any user's (whether specific to any particular
          user or as an aggregation of users' information) information
          accessible through any web sites or networks owned by Winscore Edutech
          Private ltd. "Commercial purposes" can include but is not limited to
          selling information to third parties; using the data collected to
          customize users' experiences at a site or network outside of IQWIN;
          using the data for targeted marketing campaigns not affiliated with
          IQWIN; or using the data to offer services to IQWIN users. IQWIN
          reserves the right to take immediate action against any individual or
          entity participating in any of the prohibited actions mentioned above.
        </p>
        <p className="para">
          You hereby authorize and give consent to Winscore Edutech Private Ltd.
          to send you various information, alerts, SMS, other messages, or
          calls, either directly or through any third-party service provider,
          periodically, on the telephone numbers provided and You acknowledge
          that the transmission of such messages/calls does not hold Winscore
          Edutech Private Ltd or its third-party service provider liable or
          warrant any complaint under the relevant laws, as may be applicable
          from time to time.
        </p>
        <p className="para">
          This is a non-refundable product as the users can download it
          immediately after the payment. So, no refund will be issued once the
          product is purchased. We strongly recommend to go through the demo
          samples provided in the Official You tube Channel before buying the
          subscription.
        </p>
        <p className="para">
          If any disputes or differences arouse regarding the refund of payment
          shall be addressed to<a style={{textDecoration:"none",padding:"0px 10px"}} href="mailto:info@iqwin.co">info@iqwin.co</a>
          with in 3 days of purchase.
        </p>
        <p className="para">
          This Agreement and the interpretation of its terms shall be governed
          by and construed in accordance with the laws of the India and subject
          to the exclusive jurisdiction of the courts located in Palakkad
          District, Kerala. In the event of any dispute arising out of this
          agreement, the same will be referred to arbitration of an Arbitrator
          appointed by IQWIN and the Arbitration will be governed by the
          Arbitration Act for the time being in force In India.
        </p>
        <p className="para">
          We reserve the right to change, modify, suspend, or discontinue any
          aspects, features, operations, or functionalities of the Application,
          Website, or Services at any time, without notice. Furthermore, IQWIN
          has the right to amend these Terms from time to time without prior
          notice. We do not make any commitment, express or implied, to maintain
          or continue any aspect of the Application/Website/Services.
        </p>
      </Container>
    </section>
  );
};
export default Terms;
